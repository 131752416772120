<template>
  <div>
    <b-card>
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>

        <b-form
          v-else
          novalidate
          @submit.prevent='onSubmit'
        >
          <b-row>
            <b-col cols='12'>
              <LocaleTabs
                :errors='formErrors'
                name='name'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`name.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })">
                      <b-form-input
                        v-model='form.name[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('sort')"
                vid='sort'
                :rules='{
                         numeric: true,
                       }'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('sort') }}</span>
                  </template>
                  <b-form-input
                    v-model='form.sort'
                    :placeholder="$t('sort')"
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!--            <b-col-->
            <!--              cols="12"-->
            <!--            >-->
            <!--              <validation-provider-->
            <!--                v-slot="{ errors }"-->
            <!--                vid="country_id"-->
            <!--                :name="$t('country')"-->
            <!--                rules="required"-->
            <!--              >-->
            <!--                <b-form-group-->
            <!--                  :label="$t('country')"-->
            <!--                >-->
            <!--                  <v-select-->
            <!--                    v-model="form.country_id"-->
            <!--                    :dir="$store.state.appConfig.layout.direction"-->
            <!--                    label="name"-->
            <!--                    :options="countries"-->
            <!--                    :reduce="item => item.id"-->
            <!--                    :placeholder="$t('country')"-->
            <!--                  />-->
            <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
            <!--                </b-form-group>-->
            <!--              </validation-provider>-->
            <!--            </b-col>-->

            <b-col cols='12'>
              <b-card-text class='mb-0'>
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model='form.status'
                checked='1'
                value='1'
                unchecked-value='0'
                name='check-button'
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      countries: [],
      form: {
        name: {
          en: null,
          ar: null,
        },
        sort:null,
        status: 1,
        // country_id: null,
      },
      isDataLoaded: false,
    }
  },
  created() {
    this.getCountries()
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    loadData() {
      this.axios.get(`/${this.resource}/${this.$route.params.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name,
            status: data.status,
            country_id: data.country_id,
            sort: data.sort
          }
          this.isDataLoaded = true
        })
    },
    getCountries() {
      this.axios.get('/countries/list')
        .then(res => {
          const { data } = res.data
          this.countries = data
        })
    },
  },
}
</script>
